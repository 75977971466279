@media(max-width: 768px) {
  .chanel-intro {
    font-size: 15px !important;
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .chanel-intro br {
    display: none;
  }
}
